
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavLogo from "../../assets/logo/logo.jpeg";
import locationImage from "../../assets/location.svg";
import shoppingCart from "../../assets/shopping-cart.svg";
import { IconBgRound } from "../minor-components/IconBgRound";
import { EarnDollars } from "../minor-components/EarnDollars";
import { Help } from "../minor-components/Help";
import { OrderHistory } from "../minor-components/OrderHistory";
import { Modal } from "../minor-components/Modal";
import { axiosInstance } from '../../constants/axiosInstance';
import { useAlert } from 'react-alert'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import "@reach/combobox/styles.css";

export const Navbar = (props) => {
  let navigate = useNavigate();
  const alert = useAlert();

  const {
    ready,
    value,
    suggestions,
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [inputValue, setInputValue] = useState('');

  const [showSidebar, setShowSidebar] = useState(false);
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenHelp, setIsOpenHelp] = useState(false)
  const [isOpenOrderHistory, setIsOpenOrderHistory] = useState(false)
  const [announcement, setAnnouncement] = useState('');
  const [brands, setBrands] = useState([])
  const [orders, setOrders] = useState([])
  const [cart, setCart] = useState(0)
  const [showDropdown, setShowDropdown] = useState(false);

  const config = {
    headers: {
      "Authorization": localStorage.getItem('token')
    }
  }

  const getUserCurrentLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const results = await getGeocode({ location: { lat: latitude, lng: longitude } });
            if (results[0]) {
              const formattedAddress = results[0].formatted_address;
              setInputValue(formattedAddress);
              setValue(formattedAddress);
              localStorage.setItem('location', formattedAddress);
              props.setUserGeomery({ lat: latitude, lng: longitude });
              localStorage.setItem('userGeomery', JSON.stringify([latitude, longitude]));
              props.setLocation(formattedAddress);
              props.setSearchQuery(!props.searchQuery);
            }
          } catch (error) {
            console.error("Error fetching address: ", error);
          }
        },
        (error) => {
          console.error("Error getting user location: ", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    setIsLoggedIn(!!localStorage.getItem('token'));
    if (isLoggedIn) {
      getBrands();
      getOrders();
      getCart();
      getUserCurrentLocation();
    }
    getAnnouncementList();
  }, [isLoggedIn]);

  useEffect(() => {
    const cartUpdateInterval = setInterval(() => {
      if (isLoggedIn) {
        getCart();
      }
    }, 5000);

    return () => clearInterval(cartUpdateInterval);
  }, [isLoggedIn]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    navigate("/");
    alert.show("Logout Successfully")
  };

  const getBrands = async () => {
    try { 
      axiosInstance.get('/api/v1/category/getcategories')
        .then((res) => {
          if (res.data.success) {
            setBrands(res.data.data)
          }
          else {
            console.log('No Brand Found')
          }
        })
        .catch((error) => {
          console.log(error, "Error in Fetching Brands")
        })
    }
    catch (e) {
      console.log(e)
    }
  }

  const getOrders = async () => {
    try {
      let page = 1;
      let limit = 5;
      axiosInstance.get('/api/v1/order/getallordersbyid', config, {
        params: { page, limit }
      })
        .then((res) => {
          if (res.data.success) {
            console.log(res.data.data, 'All orders')
            setOrders(res.data.data)
          }
          else {
            console.log('No Order Found')
          }
        })
        .catch((error) => {
          console.log(error, "Error in Fetching Orders")
        })
    }
    catch (e) {
      console.log(e)
    }
  }

  const getCart = async () => {
    try {
      axiosInstance.get('/api/v1/order/getcart', config)
        .then((res) => {
          if (res.data.success) {
            setCart(res.data.data.details.length)
            console.log('Cart updated:', res.data.data.details.length)
          }
          else {
            console.log('No Order Found')
            setCart(0)
          }
        })
        .catch((error) => {
          console.log(error, "Error in Fetching Orders")
          setCart(0)
        })
    }
    catch (e) {
      console.log(e)
      setCart(0)
    }
  }

  const handleBrand = async (e) => {
    const brand = e.target.innerHTML.toLowerCase();
    try {
      navigate(`/brand/${brand.toString()}`, { state: { brand: brand } });
      setShowSidebar(false)
    }
    catch (e) {
      console.log(e)
    }
  }

  const handleSidebarItemClick = () => {
    setShowSidebar(false);  // Close the sidebar when any item is clicked
  }


  const getAnnouncementList = async () => {
    await axiosInstance.get('/api/v1/announcement/getannouncement')
      .then((res) => {
        let aboutUs = res.data.data;
        if (aboutUs.length != 0) {
          setAnnouncement(aboutUs[0].announcement)
        }
      })
      .catch((err) => {
        console.error("Error fetching announcement:", err);
      })
  }

  const handleSelect = async (address) => {
    setValue(address, false);
    setInputValue(address);
    localStorage.setItem('location', address)
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = getLatLng(results[0]);
    props.setUserGeomery({ lat, lng })
    localStorage.setItem('userGeomery', JSON.stringify([lat, lng]))
    props.setLocation(results[0].formatted_address)
    props.setSearchQuery(!props.searchQuery)
    setShowDropdown(false);
  };
    
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setValue(e.target.value);
  };

  const handleInputFocus = () => {
    setShowDropdown(true);
  };

  const handleInputBlur = () => {
    setTimeout(() => setShowDropdown(false), 200);
  };

  return (
    <>
      <div className="sticky top-0 z-30 bg-white  "> 
        <div className="max-w-screen-xl  items-center flex md:gap-16 lg:16 xl:gp-16 gap-3 justify-between mx-auto py-8" style={{ height: '12vh', width: '100%' }}> 
          <div className="flex  mr-3">
            <button className="w12 flex items-center cursor-pointer" onClick={() => setShowSidebar(!showSidebar)}>
              <svg fill="black" viewBox="0 0 100 80" width="25" height="25">
                <rect width="100" height="10"></rect>
                <rect y="30" width="100" height="10"></rect>
                <rect y="60" width="100" height="10"></rect>
              </svg>
            </button>
            {showSidebar && (
          <div className={`top-0 left-0 w-64 bg-white z-30 text-white fixed h-full ${showSidebar ? "translate-x-0" : "-translate-x-full"} ease-in-out duration-300`}>
            <div className="z-10 w-full">
              <button className="text-xl text-black fixed top-7 left-9" onClick={() => setShowSidebar(false)}>
                X
              </button>
              <div className="fixed w-full  h-[70%] px-5 left-2 top-20 flex flex-col  justify-between  rounded bg-transparent">
                <div className="">
                  {!localStorage.getItem("token") ? (
                    <>
                      <Link to="/sign-up" onClick={handleSidebarItemClick}>
                        <button className="w-full py-2 my-2 text-lg text-primaryText font-semibold bg-myBg rounded text-center">
                          Sign Up
                        </button>
                      </Link>
                      <Link to="/login" onClick={handleSidebarItemClick}>
                        <button className="text-lg py-2  my-2 w-full text-primaryText font-semibold bg-white border-2 rounded text-center">
                          Sign In
                        </button>
                      </Link>
                    </>
                  ) : (
                    <button onClick={() => { handleLogout(); handleSidebarItemClick(); }} className="text-lg py-3 my-2 w-full text-primaryText font-semibold bg-white border-2 rounded text-center">
                      Logout
                    </button>
                  )}
                  <div className="pt-2 pb-1 text-lg font-bold text-primaryText">
                    Shop
                  </div>
                  <div className="flex flex-wrap gap-1 overflow-y-auto h-40">
                    {brands.map((brand) => (
                      <div key={brand.id} onClick={handleBrand} className="my-1 flex flex-wrap cursor-pointer  text-gray-700 text-[13px] bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4  font-medium rounded-full text-sm px-5 py-2.5 w-fit ">
                        {brand.brand.charAt(0).toUpperCase() + brand.brand.slice(1)}
                      </div>
                    ))}
                  </div>
                </div>
                <ul className="">
                  <li className="pt-1 pb-1 cursor-pointer text-md font-bold text-primaryText" onClick={() => { setIsOpenOrderHistory(true); handleSidebarItemClick(); }}>
                    Order History
                  </li>
                  <li className="pt-1 pb-1 cursor-pointer text-md font-bold text-primaryText">
                    <Link to="/about-us" onClick={handleSidebarItemClick}>About Us</Link>
                  </li>
                  <li className="pt-1 pb-1 cursor-pointer text-md font-bold text-primaryText">
                    <Link to="/delivery" onClick={handleSidebarItemClick}>Delivery</Link>
                  </li>
                  <li className="pt-1 pb-1 cursor-pointer text-md font-bold text-primaryText">
                    <Link to="/mail-delivery" onClick={handleSidebarItemClick}>Mail Order Marijuana</Link>
                  </li>
                  <li className="pt-1 pb-1 cursor-pointer text-md font-bold text-primaryText">
                    <Link to="/faq" onClick={handleSidebarItemClick}>FAQ's</Link>
                  </li>
                  <li className="pt-1 pb-1 cursor-pointer text-md font-bold text-primaryText">
                    <Link to="/blogs" onClick={handleSidebarItemClick}>Blogs</Link>
                  </li>
                  <li className="pt-1 pb-1 cursor-pointer text-md font-bold text-primaryText">
                    <Link to="/accounts" onClick={handleSidebarItemClick}>Account</Link>
                  </li>
                </ul>
              </div>
                  <div className="bottom-0 fixed w-full text-center">
                    <div className="flex justify-center">
                      <img className="w-[55px] rounded-lg " src={NavLogo} alt="Logo"></img>
                    </div>
                    <div className="pt-2 pb-5 text-lg font-normal text-primaryText ">
                      Good Puff Dispensary
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="lg:inline hidden">
              <Link to='/'>
                <img className="w-[80px] ml-3 rounded-lg" src={NavLogo} alt="Logo" />
              </Link>
            </div>
            <div className="lg:hidden inline">
              <Link to='/'>
                <img className="w-[80px] ml-3 rounded-lg" src={NavLogo} alt="Logo" />
              </Link>
            </div>
          </div>

          {isLoggedIn && (
            <div className="bg-[#eeeeee] focus-within:bg-white border focus-within:border-[#eeeeee] lg:w-full md:w-full sm:w-full rounded h-10 flex flex-col" style={{ height: '40px', width: '50%' }}>
              <div className="flex justify-center">
                <div className="relative flex w-full">
                  <img src={locationImage} width={16} className="ml-1" alt="Location" />
                  <input
                    value={inputValue}
                    onFocus={handleInputFocus}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    placeholder="Search Location"
                    className="w-full bg-transparent focus-within:bg-white focus-within:outline-none h-9 mx-2"
                  />

                  {suggestions.status === "OK" && showDropdown && (
                    <div className="absolute z-10 bg-white border border-gray-300 rounded w-full h-64 overflow-auto mt-10">
                      {suggestions.data.map((item, index) => (
                        <div
                          key={index}
                          className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                          onClick={() => handleSelect(item.description)}
                        >
                          {item.description}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}


          <div className="flex w-auto" id="navbar-multi-level">
            {isLoggedIn && (
              <Link to="/checkout" style={{ margin: '20px'}}>
                <IconBgRound svg={shoppingCart} bg="bg-myBg" width="12" imgWidth={4} isCart={true} totalCartItems={cart} />
              </Link>
            )}
            {!isLoggedIn ? (
              <div className="flex w-full gap-1">
                <Link to="/sign-up" className="flex-1" style={{ margin: '5px' }}>
                  <button className="w-full py-1.5 text-base text-primaryText font-semibold bg-myBg rounded text-center">
                    SignUp
                  </button>
                </Link>
                <Link to="/login" className="flex-1" style={{ margin: '5px' }}>
                  <button className="w-full py-1.5 text-base text-primaryText font-semibold bg-myBg rounded text-center">
                    Sign In
                  </button>
                </Link>
              </div>
            ) : (
              <button onClick={handleLogout} className="w-full py-1.5 text-base text-primaryText font-semibold bg-myBg rounded text-center">
                Logout
              </button>
            )}
          </div>
        </div>

        {announcement !== '' && (
          <div className="py-2 sticky w-full top-31 z-[6] bg-myBg justify-center flex overflow-x-hidden">
            <marquee width="80%" direction="left">
              <span className="text-xl">{announcement}</span>
            </marquee>
          </div>
        )}
      </div>

      <div>
        <Modal open={isOpenOrderHistory} onClose={() => setIsOpenOrderHistory(false)}>
          <OrderHistory modal={setIsOpenOrderHistory} isAdd={true} isOrders={orders} />
        </Modal>
        <Modal open={isOpen} onClose={() => setIsOpen(false)}>
          <EarnDollars modal={setIsOpen} isAdd={true} />
        </Modal>
        <Modal open={isOpenHelp} onClose={() => setIsOpenHelp(false)}>
          <Help modal={setIsOpenHelp} isAdd={true} />
        </Modal>
      </div>
    </>
  );
};