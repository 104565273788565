import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { HeroSection } from "./components/major-components/HeroSection";
import { Navbar } from "./components/major-components/Navbar";
import { SignIn } from "./components/major-components/SignIn"
import { SignUp } from "./components/major-components/SignUp"
import { PhoneNo } from "./components/major-components/PhoneNo"
import { Agevalidation } from "./components/major-components/AgeConformation"
import { ResetPassword } from "./components/major-components/ResetPassword"
import { ConformPassword } from "./components/major-components/Conform_Password"
import { AllProducts } from "./components/major-components/AllProducts"
import { SingleProduct } from "./components/major-components/SingleProduct"
import { Checkout } from "./components/major-components/Checkout"
import { Account } from "./components/major-components/Account"
import { Berbix } from "./components/major-components/Berbix"
import { Blogs } from "./components/major-components/Blogs"
import { OrderDetail } from "./components/major-components/OrderDetail"
import { AboutUs } from "./components/major-components/AboutUs";
import { MailDelivery } from "./components/major-components/MailDelivery";
import { FAQPage } from "./components/major-components/Faq";
import { Delivery } from "./components/major-components/Delivery";
import {ContactForm} from "./components/major-components/contactus";
//--------------Noty CSS----------------------
import "./assets/sass/app.scss";
import "./components/fontawesomeIcons"
import Layout from "./components/layout/Layout";
import { BlogDetails } from "./components/minor-components/BlogDetails";

export function App() {
  const [location , setLocation] = useState('')
  const [searchQuery, setSearchQuery] = useState(false);
  const [userGeomery , setUserGeomery] = useState({})
 

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyASE7MqDo7TNZ_4fmORznk_JMBFm0d_pKY`);
            
            const data = await response.json();
            
            // Check if results exist and are not empty
            if (data.results && data.results.length > 0) {
              const formattedAddress = data.results[0].formatted_address;
              console.log('Address:', formattedAddress);
              
              if (localStorage.getItem('location')) {
                const findUserCoordinates = localStorage.getItem('userGeomery');
                const filter = findUserCoordinates.split(',');
                setUserGeomery({ lat: parseFloat(filter[0]), lng: parseFloat(filter[1]) });
                setLocation(localStorage.getItem('location'));
              } else {
                localStorage.setItem('location', formattedAddress);
                setLocation(formattedAddress);
                localStorage.setItem('userGeomery', [data.results[0].geometry.location.lat, data.results[0].geometry.location.lng]);
                setUserGeomery(data.results[0].geometry.location);
              }
            } else {
              console.error("No results found for the geolocation.");
            }
          } catch (error) {
            console.error("Error getting geolocation:", error);
          }
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser.");
    }
  }, []);
   // Empty dependency array ensures this effect runs only once after the initial render



  console.log('userGeomery', userGeomery)
  
  return (
    <Layout location={location} setLocation={setLocation} searchQuery={searchQuery} setSearchQuery={setSearchQuery} userGeomery={userGeomery} setUserGeomery={setUserGeomery}>
      <Routes>
        <Route path="/" element={<HeroSection  location={location} setLocation={setLocation} setSearchQuery={setSearchQuery} searchQuery={searchQuery} userGeomery={userGeomery} setUserGeomery={setUserGeomery}/>} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/confirm-password/:token" element={<ConformPassword />} />
        <Route path="/accounts" element={<Account />} />
        <Route path="/age-verification" element={<Agevalidation />} />
        <Route path="/allProducts" element={<AllProducts />} />
        <Route path="/checkout" element={<Checkout setSearchQuery={setSearchQuery} searchQuery={searchQuery}/>} />
        <Route path="/brand/:brandName" element={<SingleProduct location={location} userGeomery={userGeomery} setSearchQuery={setSearchQuery} searchQuery={searchQuery}/>} />
        <Route path="/order/:orderid" element={<OrderDetail />} />
        <Route path="/blogs" element={<Blogs />} /> 
        <Route path="/blog/:id" element={<BlogDetails />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/mail-delivery" element={<MailDelivery />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/contact" element={<ContactForm/>}/>
      </Routes>
    </Layout>
  )
}
