import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { CheckoutProducts } from "../minor-components/CheckoutProducts";
import { CheckoutUserDetails } from "../minor-components/CheckoutUserDetails";
import { axiosInstance } from '../../constants/axiosInstance';
import { Loader } from '../minor-components/Loader';
import { useAlert } from 'react-alert';
import { Footer } from "./Footer";
import { Navbar } from "./Navbar";

export const Checkout = ({ setSearchQuery, searchQuery }) => {
  const [loading, setLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [cartUser, setCartUser] = useState([]);
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [discountCode, setDiscountCode] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [specialInstructions, setSpecialInstructions] = useState("");  // NEW: For special instructions
  const [paymentMethod, setPaymentMethod] = useState("");  // NEW: For payment method
  const[deliveryCharges, setDeliveryCharges]=useState(0);

  let navigate = useNavigate();
  const alert = useAlert();

  const fetchCart = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/api/v1/order/getcart", {
        headers: {
          "Authorization": localStorage.getItem('token')
        }
      });
      console.log('Checkout response', response);
      setCartItems(response.data.data.details);
      setSubTotalPrice(response.data.data.subTotal);
      setTotalProducts(response.data.data.totalProducts);
      setTotalPrice(response.data.data.totalPrice);
      setFinalPrice(response.data.data.totalPrice);
      setDeliveryCharges(response.data.data.deliveryCharges);
      setCartUser(response.data.data.userId);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      fetchCart();
    } else {
      navigate("/login");
    }
  }, [fetchCart, navigate]);

  const handleDelivery = async (day) => {
    console.log(day, 'day');
  };

  const handleApplyDiscount = async () => {
    try {
      const response = await axiosInstance.post("/api/v1/discount/apply", {
        code: discountCode,
        totalPrice: totalPrice,
      }, {
        headers: {
          "Authorization": localStorage.getItem('token')
        }
      });

      if (response.data.success) {
        setDiscountAmount(response.data.data.discountAmount);
        setFinalPrice(response.data.data.finalPrice);
        alert.show("Discount applied successfully");
      }
    } catch (error) {
      alert.show(error.response.data.message);
    }
  };

  // Updated to include new fields like special instructions and payment method
  const handleSubmit = async (deliveryDay, finalPrice, deliveryCharges, deliveryAddress, postalCode, paymentMethod, coordinates, discountCode, discountAmount,specialInstructions) => {
    if (paymentMethod === '' && deliveryDay === 'Same Day Delivery') {
      alert("Please Select Payment Method");
      return;
    }
  
    const order = {
      details: cartItems,
      totalPrice: parseFloat(finalPrice),
      deliveryCharges: parseFloat(deliveryCharges),
      deliveryType: deliveryDay,
      subTotal: parseFloat(subTotalPrice),
      totalProducts: totalProducts,
      formattedAddress: deliveryAddress,
      postalCode: postalCode,
      paymentMethod: paymentMethod,
      geometry: coordinates,
      discountCode: discountCode,
      discountAmount: parseFloat(discountAmount),
      finalPrice: parseFloat(finalPrice),
      specialInstructions: specialInstructions
    };
  
    try {
      console.log("Sending order data:", order);
      const response = await axiosInstance.post("/api/v1/order/placeorder", order, {
        headers: {
          "Authorization": localStorage.getItem('token')
        }
      });
  
      console.log("Server response:", response.data);
  
      if (response.data.success) {
        const deleteResponse = await axiosInstance.delete('/api/v1/order/deletecart', {
          headers: {
            "Authorization": localStorage.getItem('token')
          }
        });
  
        if (deleteResponse.data.success) {
          alert.show("Order Placed Successfully");
          setSearchQuery(!searchQuery);
          navigate('/');
        } else {
          alert.show("Failed to clear cart: " + deleteResponse.data.message);
        }
      } else {
        alert.show("Order placement failed: " + response.data.message);
      }
    } catch (err) {
      console.error("Error details:", err.response ? err.response.data : err.message);
      alert.show(err.response ? err.response.data.message : "An unexpected error occurred while placing the order");
    }
  };


  const handleItem = async (item, action) => {
    setLoading(true);
    const details = {
      productId: item.id,
      quantity: item.quantity,
    };
    let url = action === 'p' ? '/api/v1/order/addtocart' : '/api/v1/order/decreasecartquantity';

    try {
      await axiosInstance.post(url, details, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem('token')
        }
      });
      await fetchCart(); // Refresh cart data after item update
    } catch (err) {
      console.error(err);
      alert.show("Error updating cart");
    } finally {
      setLoading(false);
    }
  };

  const handleExtraItem = async (item, action) => {
    setLoading(true);
    const details = {
      productId: item.productId,
      quantity: item.quantity,
      extras: item.extras
    };
    let url = action === 'p' ? '/api/v1/order/addtocart' : '/api/v1/order/decreaseextrasquantity';

    try {
      await axiosInstance.post(url, details, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem('token')
        }
      });
      await fetchCart(); // Refresh cart data after item update
    } catch (err) {
      console.error(err);
      alert.show("Error updating cart");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!loading ? (
        <>
          <div>
            {cartItems.length !== 0 ? (
              <>
                <div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32 my-16">
                  <div className="px-4 pt-8">
                    <p className="text-xl font-medium">Order Summary</p>
                    {cartItems.map((product, index) => (
                      <CheckoutProducts
                        key={index}
                        cartProductId={product.productId._id}
                        cartProductName={product.productId.name}
                        cartProductImage={product.productId.productPhoto}
                        cartProductPrice={product.productId.price}
                        cartProductBrand={product.productId.brand}
                        cartExtras={product.extras}
                        cartProductCategory={product.productId.category}
                        cartProductDescription={product.productId.description}
                        cartProductType={product.productId.type}
                        cartProductQuantity={product.quantity}
                        getItem={handleItem}
                        getExtraItem={handleExtraItem}
                      />
                    ))}
                  </div>
                  <div className="mt-10 px-4 pt-8 lg:mt-0">
                  <CheckoutUserDetails
                      handleSubmit={handleSubmit}
                      handleDeliveryDay={handleDelivery}
                      cartUser={cartUser}
                      totalPrice={totalPrice}
                      subTotalPrice={subTotalPrice}
                      deliveryCharges={deliveryCharges}
                      setDeliveryCharges={setDeliveryCharges}
                      discountcode={discountCode}
                      setDiscountCode={setDiscountCode}
                      handleApplyDiscount={handleApplyDiscount}
                      discountAmount={discountAmount}
                      finalPrice={finalPrice}
                      paymentMethod={paymentMethod}
                      setPaymentMethod={setPaymentMethod}
                      specialInstructions={specialInstructions}
                      setSpecialInstructions={setSpecialInstructions}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="block text-center justify-center my-10">
                  <lottie-player src="https://assets1.lottiefiles.com/packages/lf20_qh5z2fdq.json" background="transparent" speed={1} style={{ height: '300px', display: 'inline-block' }} loop autoPlay />
                  <p className="text-4xl text-black font-bold">
                    Cart is Empty! Please Add some Products
                  </p>
                </div>
              </>
            )}
          </div>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
