
import { Link } from "react-router-dom";
import NavLogo from '../../assets/logo/logo.jpeg';
import googlePlay from '../../assets/google.png';
import IOSStore from '../../assets/IOS.png';
import logo from '../fontawesomeIcons/logo.jpeg'; // Adjust the path based on your project structure
import fbicon from'../fontawesomeIcons/fb-icon.png';
import instaicon from '../fontawesomeIcons/in-icon.png';
import twitter from '../fontawesomeIcons/tw-icon.png';
import youtube from '../fontawesomeIcons/yt-icon.png';



export const Footer = () => {

    return (
        <>
          {/*  <div className="h-auto bg-[#3A3A3A]">
                <div className="grid lg:grid-cols-3 auto-cols-fr md:grid-cols-1 sm:grid-cols-1 grid-cols-1 py-16  gap-3">
                    <div className='flex flex-col items-center'>
                        <Link to="/">
                            <img className='mb-8 rounded-lg  w-20' src={NavLogo} alt='footer-logo' />
                        </Link>
                        <a target="_black" href="https://play.google.com/apps?pli=1" className="cursor-pointer">
                            <img className='w-56 h-30' src={googlePlay} alt='google-play' />
                        </a>
                        <a target="_black" href="https://www.apple.com/app-store/" className="cursor-pointer">
                            <img className='w-56 h-30' src={IOSStore} alt='IOS-Store' />
                        </a>
                    </div>
          */}

          {/*
                    <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2  grid-cols-1 xxs:px-4">
                        <div className='flex  flex-col items-center'>
                            <p className='text-left text-gray-300 w-56 mb-1'>
                                <Link className="cursor-pointer" to='/about-us'>About Us</Link>
                            </p>
                            <p className='text-left text-gray-300 w-56 my-1'>
                                <Link className="cursor-pointer" to='/delivery'>Delivery</Link>
                            </p>
                            <p className='text-left text-gray-300 w-56 my-1'>
                                <Link className="cursor-pointer" to='/mail-delivery'>Mail Order Marijuana</Link>
                            </p>
                            <p className='text-left text-gray-300 w-56 my-1'>
                                <Link className="cursor-pointer" to='/faq'>FAQ's</Link>
                            </p>
                            <p className='text-left text-gray-300 w-56 my-1'>
                                <Link className="cursor-pointer" to='/blogs'>Blogs</Link>
                            </p>
                            {localStorage.getItem('token') ?
                                null
                                :
                                <>
                                    <p className='text-left text-gray-300 w-56 mb-1'>
                                        <Link to="/sign-up">Sign Up</Link>
                                    </p>
                                    <p className='text-left text-gray-300 w-56 my-1'>
                                        <Link to="/login">Sign In</Link>
                                    </p>
                                </>
                            }
                        </div>
          */}
                        {/* <div className='flex flex-col items-center '>
                            <p className='text-left text-gray-300 w-56 mb-1 '>
                                Weed Delivery Toronto
                            </p>
                            <p className='text-left text-gray-300 w-56 my-1'>
                                Weed Delivery Downtown Toronto
                            </p>
                            <p className='text-left text-gray-300 w-56 my-1'>
                                Weed Delivery North York
                            </p>
                            <p className='text-left text-gray-300 w-56 my-1'>
                                Weed Delivery Willowdale
                            </p>
                            <p className='text-left text-gray-300 w-56 my-1'>
                                Weed Delivery Lawrence Park
                            </p>
                        </div> */}
                    {/* <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 xxs:px-4">
                        <div className='flex flex-col items-center'>
                            <p className='text-left text-gray-300 w-56 mb-1'>
                                Weed Delivery Etobicoke
                            </p>
                            <p className='text-left text-gray-300 w-56 my-1'>
                                Weed Delivery Scarborough
                            </p>
                            <p className='text-left text-gray-300 w-56 my-1'>
                                Weed Delivery East York
                            </p>
                            <p className='text-left text-gray-300 w-56 my-1'>
                                Weed Delivery Vaughan
                            </p>
                            <p className='text-left text-gray-300 w-56 my-1'>
                                Weed Delivery Richmond Hill
                            </p>
                        </div>
                        <div className='flex flex-col items-center'>
                            <p className='text-left text-gray-300 w-56 mb-1'>
                                Weed Delivery Markham
                            </p>
                            <p className='text-left text-gray-300 w-56 my-1'>
                                Weed Delivery Mississauga
                            </p>
                            <p className='text-left text-gray-300 w-56 my-1'>
                                Weed Delivery Brampton
                            </p>
                        </div>
                    </div> */}



                     <footer style={{ backgroundColor: '#2c3e50', padding: '40px', color: 'white' }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        maxWidth: '1200px',
        margin: '0 auto'
      }}>

        
        {/* Left Section - Logo and Contact Information */}
        <div style={{ flex: '1', marginBottom: '20px' }}>
          <img src={logo} alt="Logo" style={{ marginBottom: '15px', width: '150px' }} />
          <h4>Store Address</h4>
          
          <p>Email: goodpuff03@gmail.com</p>
          <p>Phone: +1(323) 203-6125</p>
          <p>Hours of Operation: Mon-Sun 10 Am to 1 Am</p>
          {/* Social Media Icons */}
          <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-start' }}>
  <a href="your-facebook-link" target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
    <img src={fbicon} alt="Facebook" style={{ width: '30px' }} />
  </a>
  <a href="your-youtube-link" target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
    <img src ={youtube}  alt="YouTube" style={{ width: '30px' }} />
  </a>
  <a href="your-instagram-link" target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
    <img src={instaicon} alt="Instagram" style={{ width: '30px' }} />
  </a>
  <a href="your-twitter-link" target="_blank" rel="noopener noreferrer">
    <img src= {twitter} alt="Twitter" style={{ width: '30px' }} />
  </a>
</div>
        </div>

        {/* Center Section - Company Links */}
        <div style={{ flex: '1', marginBottom: '20px' }}>
          <h2>COMPANY</h2>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            <li><a href="/about-us" style={{ color: 'white', textDecoration: 'none' }}>About Us</a></li>
            <li><a href="/delivery" style={{ color: 'white', textDecoration: 'none' }}>Delivery</a></li>
            <li><a href="/mail-delivery" style={{ color: 'white', textDecoration: 'none' }}>Mail Order Marijuana</a></li>
            <li><a href="/privacy" style={{ color: 'white', textDecoration: 'none' }}>Privacy Statement</a></li>
            <li><a href="/blogs" style={{ color: 'white', textDecoration: 'none' }}>Blogs</a></li>
            <li><a href="/terms" style={{ color: 'white', textDecoration: 'none' }}>Terms and Conditions</a></li>
          </ul>
         {localStorage.getItem('token') ?
                                null
                                :
                                <>
                                    <p className='text-left text-gray-300 w-56 mb-1'>
                                        <Link to="/sign-up">Sign Up</Link>
                                    </p>
                                    <p className='text-left text-gray-300 w-56 my-1'>
                                        <Link to="/login">Sign In</Link>
                                    </p>
                                </>
                            }   
        </div>

        {/* Right Section - Support Links */}
        <div style={{ flex: '1', marginBottom: '20px' }}>
          <h2 >SUPPORT</h2>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            <li><a href="/contact" style={{ color: 'white', textDecoration: 'none' }}>Contact Us</a></li>
            <li><a href="/faq" style={{ color: 'white', textDecoration: 'none' }}>FAQ</a></li>
            <li><a href="#" style={{ color: 'white', textDecoration: 'none' }}>FDA Disclaimer</a></li>
            <li><a href="#" style={{ color: 'white', textDecoration: 'none' }}>Shipping Policy</a></li>
            <li><a href="#" style={{ color: 'white', textDecoration: 'none' }}>Special Discounts</a></li>
            <li><a href="#" style={{ color: 'white', textDecoration: 'none' }}>Return Policy</a></li>
          </ul>
        </div>

        {/* Newsletter Sign Up Section 
        <div style={{ flex: '1', marginBottom: '20px' }}>
          <h4>Newsletter Sign Up</h4>
          <p>Sign up for exclusive offers and discounts. Stay connected for all sales.</p>
          <form style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="email"
              placeholder="Your email"
              style={{
                padding: '10px',
                borderRadius: '5px',
                border: 'none',
                marginRight: '10px',
                flex: '1'
              }}
            />
            <button
              type="submit"
              style={{
                padding: '10px 20px',
                backgroundColor: '#8bc34a',
                border: 'none',
                borderRadius: '5px',
                color: 'white',
                cursor: 'pointer'
              }}
            >
              &rarr;
            </button>
          </form>
          {/* Payment Methods 
          <div style={{ marginTop: '20px' }}>
            <img src="visa-icon-url" alt="Visa" style={{ width: '40px', marginRight: '10px' }} />
            <img src="mastercard-icon-url" alt="MasterCard" style={{ width: '40px', marginRight: '10px' }} />
            <img src="discover-icon-url" alt="Discover" style={{ width: '40px', marginRight: '10px' }} />
            <img src="amex-icon-url" alt="Amex" style={{ width: '40px', marginRight: '10px' }} />
          </div>
        </div>
      </div>*/}
      

      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <a href="#" style={{ color: 'white', textDecoration: 'none' }}>Back to top &uarr;</a>
      </div>
    
                </div>
                <div className='text-gray-300 text-center py-3'>
                    Good Puff Dispensary © 2024
                </div>
    </footer>
  );
               
               
                <script src='https://www.gstatic.com/firebase/6.2.0/firebase.js'></script>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/firebase/9.9.2/firebase-auth.min.js" integrity="sha512-VFdqT83o0epWSKSZOGryhioVcx0sCaPbujRJ76odePTjA/Mqcwwp3Zm5jUYfi7WjRPROpAYmOzXXSEGUAtKviA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/firebase/9.9.2/firebase-app.min.js" integrity="sha512-aCu7Uq/UKhm7gcvqNmT2VcPzNTjRy5cSsyNw1EaBfLvso4CFmqvpm8Pnz+w20lsNv0rjzYwtmNqmghYuDa5k2Q==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
        </>
    )
}