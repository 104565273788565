import React, { useEffect, useState,useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { Card } from '../minor-components/Card';
import { Footer } from './Footer';
import { Loader } from '../minor-components/Loader';
import { DashboardProducts } from '../minor-components/DashboardProducts';
import { SlickSliderForHeaderImages } from '../minor-components/SlickSlider';
import { getBlogs } from '../../redux/Actions/BlogsActions';
import { selectProgressBarState } from '../../redux/Actions/ProgressBarActions';
import { axiosInstance } from "../../constants/axiosInstance";
import { baseURL } from '../../constants/baseURL';
import BottomImage from '../../assets/second-hero-section.png';
import CertificateIcon from '../fontawesomeIcons/quality.png';
import RocketIcon from '../fontawesomeIcons/charity.png';
import CharityIcon from '../fontawesomeIcons/innovation.png';
import LightbulbIcon from '../fontawesomeIcons/mission.png';
import SafetyIcon from '../fontawesomeIcons/safety.png';


export const HeroSection = (setSearchQuery, searchQuery, setLoading) => {
  const [productsWithShopNames, setProductsWithShopNames] = useState([]);
  const [alertShown, setAlertShown] = useState(false); // Initialize state to track alert
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const [products, setProducts] = useState([]);
  const [editSliderPreview, setEditSliderFilePreview] = useState('');
  const [categories, setCategories] = useState([]);
  const { blogs } = useSelector((state) => state.blogReducer);
  const loading = useSelector((state) => state.ProgressBarReducer);

  useEffect(() => {
    dispatch(getBlogs());
    fetchProducts();
    getHeaderImages();
  }, [dispatch]);

  const getHeaderImages = async () => {
    try {
      const res = await axiosInstance.get('/api/v1/headerimages/getHeaderImages');
      if (res.data.data.length > 0) {
        setEditSliderFilePreview(res.data.data[0].sliderPhoto);
      }
    } catch (err) {
      console.error("Error fetching header images:", err);
    }
  };

  const updateCartCount = useCallback((count) => {
    localStorage.setItem('totalCart', count);
    dispatch({ type: 'UPDATE_CART_COUNT', payload: count });
  }, [dispatch]);

  const handleItem = useCallback(async (item, action = 'add') => {
    setLoading(true);
    
    // Log the item to ensure shopName exists before sending to API
    console.log('HandleItem called with:', JSON.stringify(item, null, 2));
    console.log('Item shopName before API call:', item.shopName);
  
    if (!item.shopName) {
      console.error('Product does not have a shop name:', item);
      alert.show("Unable to add product. Shop information is missing.");
      setLoading(false);
      return;
    }
  
    const details = {
      productId: item._id,
      quantity: 1,
      shopName: item.shopName,  // Ensure shopName is correctly sent here
      extras: item.extras || []
    };
  
    // Log the details object being sent to the API
    console.log('Details object being sent to API:', JSON.stringify(details, null, 2));
  
    const url = action === 'add' ? '/api/v1/order/addtocart' : '/api/v1/order/decreasecartquantity';
  
    try {
      const token = localStorage.getItem('token');
  
      if (!token) {
        throw new Error('Authorization token is missing.');
      }
  
      const res = await axiosInstance.post(url, details, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        }
      });
  
      // Log the API response
      console.log('API Response:', JSON.stringify(res.data, null, 2));
  
      if (res.data && res.data.success) {
        const cartCount = res.data.data.details.length;
        updateCartCount(cartCount);
  
        const message = action === 'add' ? "Product Added to Cart" : "Product Removed from Cart";
        alert.show(message);
  
        setSearchQuery(prev => !prev);
      } else {
        throw new Error(res.data.message || "Failed to update cart");
      }
    } catch (err) {
      console.error('Error updating cart:', err);
      alert.show(err.message || "Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [setLoading, setSearchQuery, alert, updateCartCount]);


  const fetchProducts = async () => {
    dispatch(selectProgressBarState(true));
    try {
      const [productsData, categoriesData, shopData] = await Promise.all([
        axiosInstance.get("/api/v1/product/getproducts"),
        axiosInstance.get('/api/v1/category/getcategories'),
        axiosInstance.get('/api/v1/admin/getradius')
      ]);
  
      // Log raw product and shop data for debugging
      console.log('Raw product data:', JSON.stringify(productsData.data.data, null, 2));
      console.log('Shop data:', JSON.stringify(shopData.data.data, null, 2));
  
      // Create a map of shop details (shopId -> shopName)
      const shopDetails = shopData.data.data.reduce((acc, shop) => {
        acc[shop._id] = shop.shopName;
        return acc;
      }, {});
  
      // Filter and map products to only include those with valid shop names
      const productsWithShopNames = productsData.data.data
        .filter(product => shopDetails[product.shopid])  // Ensure valid shopId exists
        .map(product => {
          return {
            ...product,
            shopName: shopDetails[product.shopid]  // Assign shopName from shopDetails map
          };
        });
  
      // Log products with shop names for debugging
      console.log('Products with shop names:', JSON.stringify(productsWithShopNames, null, 2));
  
      setProductsWithShopNames(productsWithShopNames);
      setCategories(categoriesData.data.data);
  
    } catch (error) {
      console.error("Error fetching data:", error);
      alert.show("Something Went Wrong.");
    } finally {
      dispatch(selectProgressBarState(false));
    }
  };


  const AllProductsSection = React.memo(({ products, handleItem }) => {
    console.log('AllProductsSection products:', products);
    return (
      <div className='w-[90vw] mx-auto my-10'>
        <h1 className='text-primaryText font-bold text-2xl my-10'>All Products</h1>
        <div className='grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-3 grid-cols-2 gap-3'>
          {products.map((item, index) => {
            console.log(`Product ${index}:`, item);
            return (
              <div key={item._id} className='px-2 py-2'>
                <Card
                  svg={`${baseURL}${item.productPhoto}`}
                  title={item.name}
                  desc={item.description}
                  price={item.price}
                  item={item}
                  value={index}
                  shopName={item.shopName}
                  getItem={() => {
                    console.log('getItem called with:', item);
                    handleItem(item);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  });
  
  {/*const ProductsUnder40Section = React.memo(({ products, handleItem }) => {
    console.log('ProductsUnder40Section products:', products);
    return (
      <div className='w-[90vw] mx-auto my-10'>
        <h1 className='text-primaryText font-bold text-2xl my-10'>Products Under $40</h1>
        <div className='grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-3 grid-cols-2 gap-3'>
          {products.filter(item => item.price < 40).map((item, index) => {
            console.log(`Product Under 40 ${index}:`, item);
            return (
              <div key={item._id} className='px-2 py-2'>
                <Card
                  svg={`${baseURL}${item.productPhoto}`}
                  title={item.name}
                  desc={item.description}
                  price={item.price}
                  item={item}
                  value={index}
                  shopName={item.shopName}
                  getItem={() => {
                    console.log('getItem called with:', item);
                    handleItem(item);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  });
  */}
  const handleHeaderImage = (brand) => {
    if (brand === 'vapes') {
      navigate('/brand/vapes');
    } else if (brand === 'flowers') {
      navigate('/brand/flowers');
    }
  };
  
  
  
  
  const SparklesIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z"/>
      <path d="M5 3v4"/>
      <path d="M19 17v4"/>
      <path d="M3 5h4"/>
      <path d="M17 19h4"/>
    </svg>
  );
  
  const LeafIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M11 20A7 7 0 0 1 9.8 6.1C15.5 5 17 4.48 19 2c1 2 2 4.18 2 8 0 5.5-4.78 10-10 10Z"/>
      <path d="M2 21c0-3 1.85-5.36 5.08-6C9.5 14.52 12 13 13 12"/>
    </svg>
  );
  
  const AwardIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <circle cx="12" cy="8" r="6"/>
      <path d="M15.477 12.89 17 22l-5-3-5 3 1.523-9.11"/>
    </svg>
  );
  
  return (
    <>
      {!loading ? (
        <>
          < div className='w-[90vw] mx-auto my-10'>
            <h1 className='text-primaryText font-bold text-2xl my-10'>
              Browse Our Catalogues
            </h1>
            <DashboardProducts products={categories} istype='categories' />



          </div>
       
          <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '40px',
      backgroundColor: '#3e0d31',
      color: 'white',
      textAlign: 'center',
      flexWrap: 'wrap',
    }}>
      <div style={{ flex: '1', margin: '0 20px' }}>
        <img src={CertificateIcon} alt="Quality" style={{ width: '50px', marginBottom: '15px' , marginLeft: '80px'  }} />
        <h3 style={{ fontSize: '1.5rem', marginBottom: '10px' }}>Quality</h3>
        <p style={{ fontSize: '1rem' }}>
          We select only top-grade hemp plants grown on agriculturally responsible farms right here in the USA.
        </p>
      </div>

      <div style={{ flex: '1', margin: '0 20px' }}>
        <img src={SafetyIcon} alt="Safety" style={{ width: '50px', marginBottom: '15px' , marginLeft: '80px' }} />
        <h3 style={{ fontSize: '1.5rem', marginBottom: '10px' }}>Safety</h3>
        <p style={{ fontSize: '1rem' }}>
          The US Hemp Authority certifies our industry-leading standards in product safety and quality.
        </p>
      </div>

      <div style={{ flex: '1', margin: '0 20px' }}>
        <img src={LightbulbIcon} alt="Mission" style={{ width: '50px', marginBottom: '15px', marginLeft: '80px' }} />
        <h3 style={{ fontSize: '1.5rem', marginBottom: '10px' }}>Mission</h3>
        <p style={{ fontSize: '1rem' }}>
          We bring wellness to humans and the Earth by producing the highest quality CBD on the market.
        </p>
      </div>

      <div style={{ flex: '1', margin: '0 20px' }}>
        <img src={RocketIcon} alt="Charity" style={{ width: '50px', marginBottom: '15px' , marginLeft: '80px'}} />
        <h3 style={{ fontSize: '1.5rem', marginBottom: '10px' }}>Charity</h3>
        <p style={{ fontSize: '1rem' }}>
          We partner with veterans, artists, and more to help them bring wellness to their communities.
        </p>
      </div>

      <div style={{ flex: '1', margin: '0 20px' }}>
        <img src={CharityIcon} alt="Innovation" style={{ width: '50px', marginBottom: '15px' , marginLeft: '80px'}} />
        <h3 style={{ fontSize: '1.5rem', marginBottom: '10px' }}>Innovation</h3>
        <p style={{ fontSize: '1rem' }}>
          Our products are absorbed faster thanks to our nanotechnology that shrinks CBD particle size.
        </p>
      </div>
    </div>
          <SlickSliderForHeaderImages>
            <div className='relative'>
              <img
                src={editSliderPreview !== '' ? baseURL + editSliderPreview : BottomImage}
                className='md:h-[600px] px-0'
                style={{ width: '100%', height: '50vh' }}
                alt='Slider Image'
              />
              <div className='absolute opacity-[0.7] bg-slate-50 p-2 md:p-5 top-0 left-0 w-full h-full flex items-center'>
                <div className='w-[90%] border-2 bg-slate-50 md:w-96 h-auto md:h-48 px-2 py-1 md:p-5 bg-bgGrayLight rounded-3xl flex flex-col justify-center text-start'>
                  <p className='text-lg md:text-2xl text-primaryColor font-bold mb-1 md:mb-4'>
                    Vapes to Go
                  </p>
                  <p>
                    Disposable vapes are all charged up and ready to stroll
                  </p>
                  <button onClick={() => handleHeaderImage('vapes')} className='rounded-md font-semibold text-[18px] py-2 px-6 bg-[#3A3A3A] w-fit mt-4'>Vapes</button>
                  
                </div>
              </div>
            </div>
          </SlickSliderForHeaderImages>

          
          {/* All Products Section */}
         {/*
           <div className='w-[90vw] mx-auto my-10'>
            <h1 className='text-primaryText font-bold text-2xl my-10'>
              
              Products-Under $40
            </h1>
            <div className='grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-3 grid-cols-2 gap-3'>
              {products.filter((item) => item.price < 40).map((item, index) => (
                <div key={index} className='px-2 py-2'>
                  <Card
                    svg={`${baseURL}${item.productPhoto}`}
                    title={item.name}
                    desc={item.description}
                    price={item.price}
                    item={item}
                    value={index}
                    getItem={handleItem}
                  />
                </div>
              ))}
            </div>
          </div>
         */ }

          {blogs.length > 0 && (
            <div className='w-[90vw] mx-auto my-20'>
              <h2 className='text-primaryText font-bold text-2xl my-10'>
                LATEST FROM THE BLOG
              </h2>
              <DashboardProducts blogs={blogs} isBlogType={true} />
            </div>
          )} 
          
          <div className="w-full min-h-screen font-sans">
                <div className="flex flex-col lg:flex-row">
                    <div className="lg:w-1/2 p-8 lg:p-16 flex flex-col justify-center">
                        <h1 className="text-4xl lg:text-5xl font-bold mb-8">Plant-powered wellness,<br />100% clean ingredients.</h1>
                        <div>
                            <p className="mb-4 text-lg">
                                In a world where people face a relentless barrage of marketing, social media,
                                and so called Next Big Things, we want to distill things down to their essence.
                                To remove distractions, filter out the noise, and concentrate on what really
                                matters. And what really matters to us is helping people feel their best, so they
                                can give their best, every day.
                            </p>
                            <p className="mb-4 text-lg">
                                At Good Puff, our expert botanists distill hemp to its purest essence and
                                create targeted formulations to help people calibrate their health. No fluff. No
                                fillers. Just pure, effective cannabinoid products.
                            </p>
                            <p className="font-semibold mb-6 text-xl">Good Puff. Quality that Matters</p>
                            
                            <div className="flex flex-col sm:flex-row justify-between gap-6 text-center text-green-500">
                            <div className="flex flex-col items-center">
                                <SparklesIcon className="w-12 h-12 text-green-500 mb-3" />
                                <p className="font-semibold text-base">100% Clean Ingredients</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <LeafIcon className="w-12 h-12 text-green-500 mb-3" />
                                <p className="font-semibold text-base">Made From US-Grown Industrial Hemp</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <AwardIcon className="w-12 h-12 text-green-500 mb-3" />
                                <p className="font-semibold text-base">Rigorously Tested, Quality Assured</p>
                            </div>
                        </div>
                    </div>
                    </div>
                    
                    <div className="lg:w-1/2 h-[50vh] lg:h-auto">
                        <img 
                            src="/homepage.jpg"
                            alt="CBD Products" 
                            className="w-full h-full object-cover" 
                        />
                    </div>
                </div>
            </div>
          <SlickSliderForHeaderImages>
            <div className='relative'>
              <img
                src={editSliderPreview !== '' ? baseURL + editSliderPreview : BottomImage}
                className='md:h-[600px] px-0'
                style={{ width: '100%', height: '70vh' }}
                alt='Bottom Slider Image'
              />
              <div className='absolute opacity-[0.7] bg-slate-50 p-2 md:p-5 top-0 left-0 w-full h-full flex items-center'>
                <div className='w-[90%] border-2 bg-slate-50 md:w-96 h-auto md:h-48 px-2 py-1 md:p-5 bg-bgGrayLight rounded-3xl flex flex-col justify-center text-start'>
                  <p className='text-lg md:text-2xl text-primaryColor font-bold mb-1 md:mb-4'>
                    Flowers to Go
                  </p>
                  <p>
                    Disposable Flower are all charged up and ready to stroll
                  </p>
                  <button onClick={() => handleHeaderImage('flowers')} className='rounded-md font-semibold text-[18px] py-2 px-6 bg-[#3A3A3A] w-fit mt-4'>Flowers</button>
                </div>
              </div>
            </div>
          </SlickSliderForHeaderImages>
          
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
