import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "./Footer";
import { Navbar } from "./Navbar";
import { Filters } from "../minor-components/Filters";
import { DashboardProducts } from "../minor-components/DashboardProducts";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../redux/Actions/CategoryActions";
import { useState } from "react";
import { getSingleBanner } from "../../redux/Actions/CategoryBannerAction";
import { baseURL } from "../../constants/baseURL";
import { Loader } from "../minor-components/Loader";
import { axiosInstance } from "../../constants/axiosInstance";

export const SingleProduct = ({ location, setSearchQuery, searchQuery }) => {
  const { brandName } = useParams();
  const dispatch = useDispatch();

  const [banners, setBanner] = useState('');

  const { categories } = useSelector(
    (state) => state.categoryReducer
  );
  const { banner } = useSelector(
    (state) => state.categoryBannerReducer
  );
  const loading = useSelector(
    (state) => state.ProgressBarReducer
  );

  useEffect(() => {
    console.log(brandName, 'brandName');
    dispatch(getCategories());
    setBanner(categories.filter(
      (category) => category.brand.toLowerCase() === brandName.toLowerCase()
    ));
  }, [brandName, location]);

  useEffect(() => {
    dispatch(getSingleBanner(banners.length > 0 ? banners[0]._id : ''));
  }, [banners]);

  useEffect(() => {
    dispatch(getSingleBanner(banners.length > 0 ? banners[0]._id : ''));
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <div className='w-[90vw] mx-auto my-10'>
            <h1 className='text-primaryText font-bold text-2xl my-10'>
              Browse Our Catalogues
            </h1>
            <DashboardProducts setSearchQuery={setSearchQuery} searchQuery={searchQuery} products={categories} istype='categories' />
          </div>
          {banner !== null && banner && Object.keys(banner).length > 0 ? (
            <div className='w-[90vw] mx-auto my-10 bg-white rounded-sm shadow-md'>
              <div className="flex justify-between p-3">
                <div>
                  <img src={baseURL + banner?.categoryBannerPhoto} className="w-56"/>
                </div>
                <div></div>
              </div>
            </div>
          ) : null}
          <Filters setSearchQuery={setSearchQuery} searchQuery={searchQuery} />
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
