
import React , { useState } from "react";
import {  useNavigate , useLocation  } from "react-router-dom";
import NavLogo from "../../assets/nav-logo.svg";
import { axiosInstance } from '../../constants/axiosInstance';
import BerbixVerify from "berbix-react";
import PropTypes from "prop-types";
import { ViewList } from "heroicons-react";
// const jwt = require('jsonwebtoken');
// import { Footer } from "./Footer";
// import { Navbar } from "./Navbar";



export const Agevalidation = ({isVerified, setIsVerified}) => {
    const location = useLocation();
    let navigate = useNavigate();
  const [verified , setVerified] = useState('none')
  const handleSubmit = async (value) => {


    if(value === 'yes'){
      localStorage.setItem('verified', 'yes')
      setIsVerified('yes')
    }else{
      setVerified('no')
    }
  
  console.log('valuedddd', value)
  };
  return (
    <>
    <div className="h-[100vh] fixed w-full opacity-1000 z-50 flex bg-emerald-50 items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-auto shadow-md rounded-md bg-white flex justify-center">
        <div floated={false} className="h-auto p-12">
          {verified === 'none' ? 
          <div className="max-w-md w-full space-y-8">
          <div className="w-96">
            <img
              className="mx-auto  h-12 w-auto"
              src={NavLogo}
              alt="Workflow"
            />
            <h4 className="mt-2 pb-2 text-center  text-xl font-medium text-gray-600">
              Are you 21 or Older?
            </h4>
            <p className=" pb-2 px-12 text-center  text-md font-normal text-gray-400">You must be 21 years old or older to view website content.</p>
          </div>
        

            <div className="flex flex-row justify-between">
              <button
                onClick={() => handleSubmit('yes')}
                className="group relative w-40 flex justify-center py-3  border border-transparent text-sm font-medium rounded-md text-black bg-myBg hover:bg-myBg-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-myBg-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                Yes
              </button>
              <button  
             onClick={() => handleSubmit('no')}
                className="group relative w-40 flex justify-center py-3   border-2 text-sm font-medium rounded-md text-black bg-white  hover:bg-myBg-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-myBg-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                No
              </button>
            </div>
         
        </div>
        :
        <div className="max-w-md w-full space-y-8">
          <h1 className="font-bold text-center text-[32px]">Sorry, you are not allowed to access the website content due to age restrictions</h1>
        </div>
        }
          
        </div>
      </div>
    </div>
    </>
  );
};
