
import  {initializeApp} from 'firebase/app';
import  {getAuth}  from "firebase/auth";

  const firebaseConfig = {
    apiKey: "AIzaSyCkSmqlpKqtvxa6YXnJgNM4VtlkJGrB5M0",
    
    
    authDomain: "goodpuff.firebaseapp.com",
    projectId: "goodpuff",
    storageBucket: "goodpuff.appspot.com",
    messagingSenderId: "247649406169",
    appId: "1:247649406169:web:37ed4723619b10bf7c42c8",
    measurementId: "G-KZ2QZM12QH"
  
  }

const app = initializeApp(firebaseConfig)

export const authentication = getAuth(app);